import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RamadanBuzzOffer from "./pages/RamadanBuzzOffer/RamadanBuzzOffer";
import SummerOffer from "./pages/SummerOffer/SummerOffer";

function App() {
  return (
    <div className="App">
      <main>
        <BrowserRouter>
          <Routes>
          <Route path="/" element={<SummerOffer />} />
            <Route path="ramadan-buzz-offer" element={<RamadanBuzzOffer />} />
          </Routes>
        </BrowserRouter>
      </main>
    </div>
  );
}

export default App;
