import React from "react";
import Logo from "../../images/Logo.png";
import { useTranslation } from 'react-i18next';
import "../PromotionFooter/PromotionFooter.css"

const PromotionFooter = () => {
  const { t, i18n } = useTranslation();
  return (
    <footer className="section-spacing">
      <div className="text-center">
        <img className="mb-5" src={Logo} />
        <div className="footer-credit">
          <p>{t("Copyright © 2024 Watani Auto")}</p>
          <p>{t("All rights reserved")}</p>
        </div>
      </div>
    </footer>
  );
};

export default PromotionFooter;
