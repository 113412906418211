import React from "react";
import PromotionHeader from "../../components/PromotionHeader/PromotionHeader";
import PromotionFooter from "../../components/PromotionFooter/PromotionFooter";
import RamadanOfferBanner from "../../images/ramadan-buzz-offers.png";
import "../RamadanBuzzOffer/RamadanBuzzOffer.css";
import OfferForm from "../../components/OfferForm/OfferForm";

const RamadanBuzzOffer = () => {

    const id = "Ramadan Buzz Offer";
    
  return (

    <div>
    <PromotionHeader />
    <div className="offersBannerContent">
        <div className="offersBanner">
          <img src={RamadanOfferBanner} />
          <div className="offersBannerCaption">
            <h1>Ramadan Buzz Offer</h1>
            <h1>2024 Offer</h1>
            <p>Offer valid till 31st March</p>
          </div>
        </div>
        </div>
        <OfferForm id={id} />
    <PromotionFooter />
    </div>
    
    
  );
};

export default RamadanBuzzOffer;
