import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col, Spinner } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import FormSuccess from "../FormSuccess/FormSuccess";
import "../OfferForm/OfferForm.css";
import { useTranslation } from "react-i18next";
import axios from "axios";

const utmURL = window.location.href;

const OfferForm = ({ id }) => {
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    city: "",
    area: "",
    car_brand: "",
    car_model: "",
    payment: "cash",
    offerName: id,
    UTM: utmURL,
    comment: "",
  });

  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [areas, setAreas] = useState([]);
  const [models, setModels] = useState([]);

  const cities = [
    "Dammam",
    "Khobar",
    "Jazan",
    "Madina",
    "Najran",
    "Taif",
    "Tabuk",
    "Rabigh",
    "Yanbu",
    "Makkah",
    "Other",
    "KhamisMushayt",
    "AlBaha",
    "Abha",
    "AlQoutif",
    "Buraidah",
    "Unizah",
    "AlKharj",
    "Jubail",
    "HafarAlBatin",
    "AlQassim",
    "AlJouf",
    "Hail",
    "Arar",
    "AlGurayat",
    "Hofuf",
    "AlAhsa",
    "Sakaka",
    "Dahran",
    "AlRass",
    "Dawadmi",
    "Jeddah",
    "Riyadh",
  ];

  const areasByCity = {
    Dammam: ["Dammam"],
    Khobar: ["Khobar"],
    Jazan: ["Jazan"],
    Madina: ["Madina"],
    Najran: ["Najran"],
    Tabuk: ["Tabuk"],
    Rabigh: ["Rabigh"],
    Yanbu: ["Yanbu"],
    Makkah: ["Makkah"],
    Taif: ["Taif"],
    Other: ["Other"],
    KhamisMushayt: ["Khamis Mushayt"],
    AlBaha: ["Al Baha"],
    Abha: ["Abha"],
    AlQoutif: ["Al Qoutif"],
    Buraidah: ["Buraidah"],
    Unizah: ["Unizah"],
    AlKharj: ["Al Kharj"],
    Jubail: ["Jubail"],
    HafarAlBatin: ["Hafar Al Batin"],
    AlQassim: ["Al Qassim"],
    AlJouf: ["Al Jouf"],
    Hail: ["Hail"],
    Arar: ["Ar'ar"],
    AlGurayat: ["Al Gurayat"],
    Hofuf: ["Hofuf"],
    AlAhsa: ["Al Ahsa"],
    Sakaka: ["Sakaka"],
    Dahran: ["Dahran"],
    AlRass: ["AlRass"],
    Dawadmi: ["Dawadmi"],
    Jeddah: ["Jeddah North", "Jeddah South", "Jeddah Middle"],
    Riyadh: ["Riyadh North", "Riyadh East", "Riyadh West", "Riyadh South"],
  };

  const brands = [
    "Toyota",
    "KIA",
    "Hyundai",
    "Geely",
    "Peugeot",
    "Mitsubishi",
    "Nissan",
    "MG",
    "Bestune",
    "Chery",
    "Haval",
    "Changan",
    "Jetour",
    "GAC",
    "Suzuki",
  ];

  const modelsbyBrand = {
    Toyota: [
      "Raiz",
      "Yaris",
      "Rush",
      "Urban Cruiser",
      "Corolla",
      "Camry",
      "RAV4",
      "Prado",
      "Crown",
    ],
    KIA: [
      "Picanto",
      "Pegas",
      "RIO",
      "Sonet",
      "Seltos",
      "Cerato",
      "Carens",
      "Niro",
      "K5",
      "Sportage",
      "Sorento",
      "K8",
    ],
    Hyundai: [
      "Venue",
      "Accent",
      "Elantra",
      "Creta",
      "Tucson",
      "Sonata",
      "Kona",
    ],
    Geely: [
      "Emgrand",
      "Coolray",
      "Azkarra",
      "Okavango",
      "Tugella",
      "Monjaro",
      "Binray",
    ],
    Peugeot: ["208", "2008", "LandTrek", "3008", "5008", "508"],
    Mitsubishi: [
      "Attrag",
      "Space Star",
      "X-Pander",
      "Eclispse Cross",
      "OutLander",
    ],
    Nissan: [
      "Sunny",
      "Kicks",
      "Altima",
      "X-Trail",
      "Pathfinder",
      "Patrol",
      "Sentra",
    ],
    MG: ["5", "GT", "ZS", "6", "RX5", "ONE", "HS", "T60", "RX8"],
    Bestune: ["T77 Pro", "B70", "B70S", "T99"],
    Chery: [
      "Arrizo5",
      "Tiggo2 Pro",
      "Tiggo4 Pro",
      "Arrizo6 Pro",
      "Tiggo7 Pro",
      "Tiggo8 Pro",
    ],
    Haval: ["Jolion", "H6", "Darago", "H6 GT", "H9", "H2"],
    Changan: [
      "Alsvin",
      "Eado Plus",
      "CS35",
      "UNI-V",
      "UNI-T",
      "UNI-K",
      "CS75",
      "CS35",
      "CS95",
      "CS85",
      "Hunter",
    ],
    Jetour: ["X70", "X70 Plus", "Dashing", "X90 Plus"],
    GAC: [
      "GS63",
      "GA4",
      "GS4",
      "GS5",
      "GA6",
      "EMPOW",
      "GA8",
      "GS8",
      "GN6",
      "GN8",
    ],
    Suzuki: [
      "Dzire",
      "Baleno",
      "Ciaz",
      "Swift",
      "Ertiga",
      "Grand Vitara",
      "Jimny",
    ],
  };

  useEffect(() => {
    if (formData.city) {
      setAreas(areasByCity[formData.city] || []);
      setFormData({ ...formData, area: "" }); // Reset the area when the city changes
    } else {
      setAreas([]);
    }
  }, [formData.city]);

  useEffect(() => {
    if (formData.car_brand) {
      setModels(modelsbyBrand[formData.car_brand] || []);
      setFormData((prevFormData) => ({ ...prevFormData, car_model: "" }));
    } else {
      setModels([]);
    }
  }, [formData.car_brand]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRadioChange = (e) => {
    setFormData({ ...formData, payment: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validation
    const errors = {};
    if (formData.name.length < 3 || !/^[a-z A-Z ]+$/.test(formData.name)) {
      errors.name = t(
        "Full Name is required and must be at least 3 characters without numbers"
      );
    }
    if (!formData.phone || !/^\d{10}$/.test(formData.phone)) {
      errors.phone = t("Phone Number is required and must be 10 digits");
    }
    if (!formData.city) {
      errors.city = t("City is required");
    }
    if (!formData.area) {
      errors.area = t("Area is required");
    }
    if (!formData.car_brand) {
      errors.car_brand = t("Brand is required");
    }
    if (!formData.car_model) {
      errors.car_model = t("Model is required");
    }
    if (!formData.payment) {
      errors.payment = t("Payment Method is required");
    }
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      setLoading(true); // Set loading state to true
      console.log("Form submitted:", formData);

      // Make POST request using Axios
      axios
        .post("https://newapi.nhcops.com/offers", formData)
        .then((response) => {
          console.log("Form submission response:", response);
          setFormSubmitted(true);
          setLoading(false); // Set loading state to false after submission
          // Reset form data after successful submission
          setTimeout(() => {
            setFormSubmitted(false);
            setFormData({
              name: "",
              phone: "",
              email: "",
              city: "",
              area: "",
              car_brand: "",
              car_model: "",
              payment: "cash",
              offerName: id,
              comment: "",
              UTM: utmURL,
            });
          }, 3000);
        })
        // .catch((error) => {
        //   console.error("Form submission error:", error);
        //   if (
        //     error.response &&
        //     error.response.data &&
        //     error.response.data.message
        //   ) {
        //     setServerError(error.response.data.message) 
        //         if (error.response.data.message === "Phone number already exists") {
        //              const serverPhoneError = error.response.data.message;
        //         }
            
        //   } else {
        //     setServerError("An error occurred while submitting the form.");
        //   }
        //   setLoading(false); // Set loading state to false if submission fails

        //   setTimeout(() => {
        //     setServerError("");
        //   }, 2000);
        // });
        .catch((error) => {
            console.error("Form submission error:", error);
            setLoading(false);
          
            let errorMessage = error.response?.data?.message || "An error occurred";
          
            // Translate specific error messages if a translation is available
            if (errorMessage === "Phone number already exists") {
              errorMessage = t("Phone number already exists");
            }
          
            setServerError(errorMessage);
          
            setTimeout(() => {
              setServerError(""); // Clear the error message after 4 seconds
            }, 4000);
          });
    }
  };

  return (
    <section className="section-spacing">
      {!formSubmitted ? (
        <Container>
          <div className="section-title">
            <h2 className="text-center">{t("Register Interest")}</h2>
            <p className="title-desc-primary text-center">
              {t(
                "Fill the form below to get in touch with one of our representatives"
              )}
            </p>
          </div>
          <Form onSubmit={handleSubmit}>
            {serverError && (
              <div className="align-server-error">
                <Alert variant="danger" className="text-center">
                  {serverError}
                </Alert>
              </div>
            )}
            <Row>
              <Col sm={12} md={6} lg={6}>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Control
                    type="text"
                    placeholder={t("Enter your full name")}
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                    className="brand-input"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Control
                    type="email"
                    placeholder={t("Enter your Email")}
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    // isInvalid={!!errors.email}
                    className="brand-input"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Control
                    type="tel"
                    placeholder={t("Enter your Contact Number")}
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    isInvalid={!!errors.phone}
                    className="brand-input"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <Form.Group className="mb-3" controlId="city">
                  <Form.Select
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    isInvalid={!!errors.city}
                    className="brand-input"
                  >
                    <option value="">{t("Select City")}</option>
                    {cities.map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.city}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <Form.Group className="mb-3" controlId="area">
                  <Form.Select
                    name="area"
                    value={formData.area}
                    onChange={handleChange}
                    isInvalid={!!errors.area}
                    className="brand-input"
                    disabled={!formData.city}
                  >
                    <option value="">{t("Select Area")}</option>
                    {areas.map((area) => (
                      <option key={area} value={area}>
                        {area}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.area}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <Form.Group className="mb-3" controlId="car_brand">
                  <Form.Select
                    name="car_brand"
                    value={formData.car_brand}
                    onChange={handleChange}
                    isInvalid={!!errors.car_brand}
                    className="brand-input"
                  >
                    <option value="">{t("Select Brand")}</option>
                    {brands.map((car_brand) => (
                      <option key={car_brand} value={car_brand}>
                        {car_brand}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.car_brand}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <Form.Group className="mb-3" controlId="car_model">
                  <Form.Select
                    name="car_model"
                    value={formData.car_model}
                    onChange={handleChange}
                    isInvalid={!!errors.car_model}
                    className="brand-input"
                    disabled={!formData.car_brand}
                  >
                    <option value="">{t("Select Model")}</option>
                    {models.map((car_model) => (
                      <option key={car_model} value={car_model}>
                        {car_model}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.car_model}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <Form.Group className="mb-3" controlId="comment">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder={t("Comment")}
                    name="comment"
                    value={formData.comment}
                    onChange={handleChange}
                    className="brand-input"
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <Form.Group className="mb-3" controlId="payment">
                  {/* <Form.Label as="legend" column sm={4}>
                      {t("Select Payment Method")}
                    </Form.Label> */}

                  <Form.Check
                    inline
                    type="radio"
                    label={t("Cash")}
                    name="payment"
                    value="cash"
                    checked={formData.payment === "cash"}
                    onChange={handleRadioChange}
                    isInvalid={!!errors.payment}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label={t("Installments")}
                    name="payment"
                    value="installments"
                    checked={formData.payment === "installments"}
                    onChange={handleRadioChange}
                    isInvalid={!!errors.payment}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.payment}
                  </Form.Control.Feedback>
                </Form.Group>
                
                <div className="d-grid gap-2">
                <Button type="submit" className="brand-btn">
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    t("Submit")
                  )}
                </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      ) : (
        formSubmitted && <FormSuccess phone={formData.phone} />
      )}
    </section>
  );
};

export default OfferForm;
