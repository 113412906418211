import React from "react";
import { Icon } from "@iconify/react";
import "../FormSuccess/FormSuccess.css";
import { Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const FormSuccess = ({phone}) => {
  const { t, i18n } = useTranslation();
  return (
    <section className="section-spacing">
      <Container>
        <div className="text-center">
          <Icon icon="simple-line-icons:check" className="successCheck" />
          <div className="section-title">
            <h2 className="text-center">{t("Form Submitted Successfully")}</h2>
            <p className="title-desc-primary text-center">
            {t("One of our representatives will call your shortly.")}
            </p>
        
            <p className="title-desc-primary text-center">
            {t("A confirmation SMS has been sent to")} {phone}
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FormSuccess;
