import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Translation files
const resources = {
  en: {
    translation: {
      "Benefit of one from the following offers and more….":
        "Benefit of one from the following offers and more….",
      "Option 1": "Option 1",
      "Free 2 installments": "Free 2 installments",
      "0% Down Payment": "0% Down Payment",
      "0% Admin Fees": "0% Admin Fees",
      "Option 2": "Option 2",
      "Postpone 3 installments": "Postpone 3 installments",
      "Option 3": "Option 3",
      "3 Free installments": "3 Free installments",
      "Option 4": "Option 4",
      "40% Down Payment": "40% Down Payment",
      "60% Balloon Payment": "60% Balloon Payment",
      "0% Profit Rate": "0% Profit Rate",
      "Register Interest": "Register Interest",
      "Fill the form below to get in touch with one of our representatives":
        "Fill the form below to get in touch with one of our representatives",
      "Enter your full name": "Enter your full name",
      "Enter your Email": "Enter your Email",
      "Enter your Contact Number": "Enter your Contact Number",
      "Select City": "Select City",
      "Select Area": "Select Area",
      "Select Brand": "Select Brand",
      "Select Model": "Select Model",
      "Comment": "Comment",
      "Cash": "Cash",
      "Installments": "Installments",
      "Submit": "Submit",
      "Submitting...": "Submitting...",
      "Full Name is required and must be at least 3 characters without numbers": "Full Name is required and must be at least 3 characters without numbers",
      "Phone Number is required and must be 10 digits": "Phone Number is required and must be 10 digits",
      "City is required": "City is required",
      "Area is required": "Area is required",
      "Brand is required": "Brand is required",
      "Model is required": "Model is required",
      "Payment Method is required": "Payment Method is required",
      "Copyright © 2024 Watani Auto":"Copyright © 2024 Watani Auto",
      "All rights reserved":"All rights reserved",
      "Form Submitted Successfully": "Form Submitted Successfully",
      "One of our representatives will call your shortly.":"One of our representatives will call your shortly.",
      "A confirmation SMS has been sent to":"A confirmation SMS has been sent to",
      "*Offers are subjected to the terms and conditions of the financing entities.":"*Offers are subjected to the terms and conditions of the financing entities.",
      "*The car in the ad might be different from the car displayed at the showrooms.":"*The car in the ad might be different from the car displayed at the showrooms.",
      "*Some of these offers are applicable on specific brands and models.":"*Some of these offers are applicable on specific brands and models.",
      "*Each offer is not valid with other offers and should not be added to another offer.":"*Each offer is not valid with other offers and should not be added to another offer.",
      "*Offers are subjected to the terms and conditions of the financing entities.":"*Offers are subjected to the terms and conditions of the financing entities.",
      "Phone number already exists":"Phone number already exists"
    },
  },
  ar: {
    translation: {
      "Benefit of one from the following offers and more….":
        "استفد من إحدى العروض التالية وأكثر...",
      "Option 1": "الخيار 1",
      "Free 2 installments": "قسطين مجانيين",
      "0% Down Payment": "0٪ دفعة أولى",
      "0% Admin Fees": "0٪ رسوم إدارية",
      "Option 2": "الخيار 2",
      "Postpone 3 installments": "تأجيل 3 أقساط",
      "Option 3": "الخيار 3",
      "3 Free installments": "3 أقساط مجانية",
      "Option 4": "الخيار 4",
      "40% Down Payment": "40٪ دفعة أولى",
      "60% Balloon Payment": "60٪ الدفعه الأخيرة",
      "0% Profit Rate": "0٪ نسبة ربح",
      "Register Interest": "سجل اهتمامك",
      "Fill the form below to get in touch with one of our representatives":"املأ النموذج أدناه للتواصل مع أحد ممثلينا",
      "Enter your full name": "أدخل اسمك الكامل",
      "Enter your Email": "أدخل بريدك الإلكتروني",
      "Enter your Contact Number": "أدخل رقم الاتصال الخاص بك",
      "Select City": "اختر مدينة",
      "Select Area": "حدد المنطقة",
      "Select Brand": "حدد العلامة التجارية",
      "Select Model": "حدد الفئة",
      "Comment": "تعليق",
      "Cash": "نقدي",
      "Installments": "أقساط",
      "Submit": "إرسال",
      "Submitting...": "إرسال...",
      "Full Name is required and must be at least 3 characters without numbers":"الاسم الكامل مطلوب ويجب أن يتكون من 3 أحرف على الأقل بدون أرقام",
      "Phone Number is required and must be 10 digits": "رقم الهاتف مطلوب ويجب أن يتكون من 10 أرقام",
      "City is required": "المدينة مطلوبة",
      "Area is required": "المنطقة مطلوبة",
      "Brand is required": "العلامة التجارية مطلوبة",
      "Model is required": "النموذج مطلوب",
      "Payment Method is required": "طريقة الدفع مطلوبة",
      "Copyright © 2024 Watani Auto":"حقوق النشر © 2024 الوطني للسيارات",
      "All rights reserved":"كل الحقوق محفوظة",
      "Form Submitted Successfully": "استقبلنا طلبك بنجاح",
      "One of our representatives will call your shortly.":"سيقوم أحد ممثلينا بالاتصال بك قريبًا.",
      "A confirmation SMS has been sent to":"تم إرسال رسالة تأكيد قصيرة إلى",
      "*Offer's terms and conditions will apply.":"* سيتم تطبيق شروط وأحكام العروض.",
      "*The car in the ad might be different from the car displayed at the showrooms.":"*قد تكون السيارة الموجودة في الإعلان مختلفة عن السيارة المعروضة في صالات العرض",
      "*Some of these offers are applicable on specific brands and models.":"*تطبق بعض هذه العروض على علامات تجارية وموديلات محددة.",
      "*Each offer is not valid with other offers and should not be added to another offer.":"*كل عرض غير صالح مع العروض الأخرى ولا يجوز إضافته إلى عرض آخر.",
      "*Offers are subjected to the terms and conditions of the financing entities.":"*تخضع العروض لشروط وأحكام الجهات التمويلية.",
      "Phone number already exists":"رقم الهاتف موجود بالفعل"
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ar", // default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});



export default i18n;
